@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
body{
  background-color: #31363F;
  font-family: 'Courier New', Courier, monospace;
  
}